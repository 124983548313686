.App {
  text-align: center;
}

.logo-container {
  width: 50vw;
}

.App-header {
  background-color: #282c34;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.coming {
  font-size: 3vmin;
}

.links {
  margin-top: 50px;
}